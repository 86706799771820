<template>

  <main>
    <article>
      <section>
        <div class="container mt-4">
          <div class="highlights_container">
            <div class="highlights">
              <div v-if="safari_tour.image">
                <div style="background-color:rgba(0,0,0,0.4); width: 100%; border-radius: 5px;">
                  <img
                    class="d-block w-100"
                    :src="safari_tour.image"
                    :alt="safari_tour.title"
                  >
                </div>
                <div class="highlights_description">
                  <div class="w-100">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/safari-packages' }">Safari Packages</el-breadcrumb-item>
                      <el-breadcrumb-item>{{safari_tour.title}}</el-breadcrumb-item>
                    </el-breadcrumb>
                  </div>
                  <div>
                    <h1><strong>{{safari_tour.title}}</strong></h1>
                  </div>
                </div>
              </div>
              <div
                v-else
                style="height: 100%; border-radius: 5px;"
              >
                <div
                  class="skeleton-block skeleton-effect-wave"
                  style="height: 100%; width: 100%; border-radius: 5px;"
                >
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>

    <article>
      <section>
        <div
          class="container"
          style="padding-top: 50px; padding-bottom: 50px;"
        >

          <div class="package_details_container">
            <div>
              <section>
                <div class="package_tabs">
                  <el-tabs
                    type="border-card"
                    :stretch="true"
                  >
                    <el-tab-pane label="Overview">
                      <div class="mt-4">
                        <div
                          v-if="safari_tour.brief_description"
                          style="width: 100% !important;"
                        >
                          <span v-html="safari_tour.brief_description"></span>
                        </div>
                        <div
                          class="w-100"
                          v-else
                        >
                          <span
                            class="skeleton-block skeleton-effect-wave"
                            style="height: 20px; width: 80%;"
                          ></span><br />
                          <div
                            v-for="(item, index) in 4"
                            :key="index"
                          >
                            <span
                              class="skeleton-block skeleton-effect-wave"
                              style="height: 10px; width: 50%;"
                            ></span><br />
                          </div>
                        </div>

                        <div class="mt-5">
                          <hr />
                          <h5><strong>Itinerary</strong></h5>
                          <p>{{safari_tour.destination_country}}
                            <!-- <img
                              src="../assets/images/Logos/ugx.svg"
                              width="3%"
                            > -->
                          </p>

                          <ul class="check-list">
                            <li
                              v-for="d of days"
                              :key="d.id"
                            >
                              Day {{d.day}}: {{d.main_destination}}
                            </li>
                          </ul>
                        </div>

                        <div class="mt-5">
                          <hr />
                          <h5><strong>Accommodation & Meals</strong></h5>
                          <p><strong>Note: </strong>Additional accommodation before and at the end of the tour can be arranged for an extra cost</p>
                          <div class="table-responsive">
                            <el-table
                              :data="days"
                              style="width: 100%"
                              :row-class-name="tableRowClassName"
                              :header-row-class-name="tableHeaderRowClassName"
                            >
                              <el-table-column
                                prop="day"
                                label="Day"
                                width="50"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="accomodation"
                                label="Accommodation"
                                width="auto"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="meals"
                                label="Meals"
                                width="auto"
                              >
                              </el-table-column>
                            </el-table>
                          </div>
                        </div>

                        <div class="mt-5">
                          <h5><strong>Activities & Transportation</strong></h5>
                          <ul class="check-list">
                            <li
                              v-for="ac of activities"
                              :key="ac.id"
                            >
                              {{ac.title}}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="Day by Day">
                      <div id="dayByDay_acc">
                        <!-- <div class="mt-4">
                          <h2 style="font-size: 1.2em;"><strong>Arrival</strong></h2>
                          <ul class="check-list">
                            <li>You'll be collected from the airport.</li>
                            <li>Accommodation before the tour starts can be arranged for an extra cost.</li>
                          </ul>
                          <hr />
                        </div> -->

                        <div v-if="days.length">
                          <div
                            v-for="d of days"
                            :key="d.id"
                          >
                            <div style="position: relative;">
                              <div>
                                <div style="width: 100%;">
                                  <img
                                    class="d-block w-100"
                                    :src="d.featuring_photo"
                                    alt="antelope"
                                    style="height: 200px; width: 100%; object-fit: cover;"
                                  >
                                </div>
                                <div class="day_image_description">
                                  <div>
                                    <span style="font-size: 2.0em; color: white;"><strong>Day {{d.day}}</strong></span><br />
                                    <span style="font-size: 1.0em; color: white;">{{d.main_destination}}.</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br />
                            <p
                              class=""
                              style="font-size: 0.85em;"
                            >
                              {{d.description}}
                            </p>
                            <p>
                              <span><strong>Main Destination: </strong></span>
                              <span>{{d.main_destination}}</span>
                            </p>
                            <div>
                              <span><strong>Accomodation: </strong></span>
                              <span>{{d.accomodation}}</span>
                              <ul
                                class="check-list"
                                style="font-size: 0.85em;"
                              >
                                <li>{{d.accomodation}}</li>
                              </ul>
                            </div>
                            <div class="mb-5">
                              <span><strong>Meals & Drinks: </strong></span>
                              <span>{{d.main_destination}}</span>
                              <ul
                                class="check-list"
                                style="font-size: 0.85em;"
                              >
                                <li>{{d.meals}}</li>
                                <li>Drinking water (Other drinks not included)</li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div v-else>
                          <div
                            class="skeleton-block skeleton-effect-wave"
                            style="height: 300px; width: 100%; border-radius: 5px;"
                          ></div>
                        </div>

                        <div>
                          <hr />
                          <h2
                            class="fs6"
                            style="font-size: 1.2em;"
                          ><strong>End of tour</strong> (See Getting There for more details)</h2>
                          <ul class="check-list">
                            <li>Additional accommodation can be arranged for an extra cost.</li>
                          </ul>
                          <br /><br />
                        </div>

                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="Rates">
                      <div
                        id="rates_acc"
                        class="pt-4"
                      >
                        <ul class="check-list">
                          <li>Rates are per person and exclude the international flight from/to your home country.</li>
                          <li>This tour accepts children who are 15 years and older.</li>
                        </ul>
                        <hr />

                        <div class="table-responsive">
                          <el-table
                            :data="rates"
                            style="width: 100%"
                            :row-class-name="tableRowClassName"
                            :header-row-class-name="tableHeaderRowClassName"
                          >
                            <el-table-column
                              prop="number_of_travellers"
                              label="Number of persons"
                              width="auto"
                            >
                            </el-table-column>
                            <el-table-column
                              prop="rate_amount"
                              label="Rate per person (USD)"
                              width="auto"
                            >
                            </el-table-column>
                          </el-table>
                        </div>

                      </div>
                    </el-tab-pane>
                    <el-tab-pane
                      class="onDesktopOnly"
                      label="Inclusions"
                    >
                      <div
                        id="inclusions_acc"
                        class="pt-4"
                      >
                        <div class="row">
                          <div class="col-md-6">
                            <h3 style="font-size: 1.2em;">Included</h3>
                            <hr />
                            <span v-html="inclusions_and_exclusion"></span>
                          </div>
                        </div>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane
                      class="onDesktopOnly"
                      label="Getting There"
                    >
                      <div
                        id="gettingThere_acc"
                        class="pt-4"
                      >
                        <h2 style="font-size: 1.2em;">Getting there</h2>
                        <hr />
                        <span v-html="getting_there"></span>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </section>

              <div
                class="interested_mobile"
                style="padding: 20px;"
              >
                <br /><br />
                <h5><strong>Interested in This Tour?</strong></h5>
                <hr />
                <div>
                  <div v-if="safari_tour.brief_description">
                    <a href="#request-quote">
                      <el-button
                        type="warning"
                        class="btn btn-brown btn-md"
                        style="background-color: #D96B12; color: white;"
                      >
                        Get a Free Quote
                        <span class="el-icon-d-arrow-right"></span>
                      </el-button>
                    </a>
                  </div>
                  <div
                    v-else
                    style="height: 100%;"
                  >
                    <span
                      class="skeleton-block skeleton-effect-wave"
                      style="height: 20px; width: 80%;"
                    ></span><br />
                    <span
                      class="skeleton-block skeleton-effect-wave"
                      style="height: 10px; width: 50%;"
                    ></span><br />
                  </div>
                </div>
              </div>
            </div>
            <div id="request-quote">
              <div>
                <div v-if="safari_tour.brief_description">
                  <section>
                    <article>
                      <div style="
                padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  background-color: #F0F9EB; border-radius: 5px;">

                        <div v-if="totalTravellers <= 6">
                          <p style="color: green;">
                            From
                            <strong>
                              <span v-if="totalRateAmount">
                                ${{ totalRateAmount.rate_amount }}
                              </span>
                            </strong> per person
                          </p>
                        </div>
                        <div v-else>
                          <p style="color: green;">
                            From
                            <strong>
                              <span v-if="totalRateAmountForMorePeople">
                                ${{ totalRateAmountForMorePeople.rate_amount }}
                              </span>
                            </strong> per person
                          </p>
                        </div>

                        <h2 style="font-size: 1.3em; font-weight: 600;">Request A Quote</h2>
                        <el-form
                          :model="ruleForm"
                          :rules="rules"
                          label-position="top"
                          ref="ruleForm"
                        >

                          <div style="display: flex;">
                            <el-form-item
                              label="Start Date"
                              prop="check_in_date"
                              style="width: 100%;"
                            >
                              <el-date-picker
                                v-model="ruleForm.check_in_date"
                                type="date"
                                placeholder="Choose date"
                                style="width: 100%"
                              >
                              </el-date-picker>
                            </el-form-item>
                          </div>

                          <div style="display: flex">
                            <el-form-item
                              label="Adults (18+ years):"
                              prop="number_adult_guest"
                            >
                              <el-input-number
                                v-model="ruleForm.number_adult_guest"
                                controls-position="right"
                                style="width: 100%"
                                @change="handleChange"
                                :min="1"
                                :max="1000"
                              >
                              </el-input-number>
                            </el-form-item>
                            <el-form-item
                              label="Children (0-17 years):"
                              prop="number_children_guest"
                            >
                              <el-input-number
                                v-model="ruleForm.number_children_guest"
                                controls-position="right"
                                style="width: 100%"
                                @change="handleChange"
                                :min="0"
                                :max="1000"
                              >
                              </el-input-number>
                            </el-form-item>
                          </div>

                          <div class="row mt-2">
                            <div class="col-md-12">
                              <el-form-item>
                                <el-button
                                  type="warning"
                                  @click="submitForm('ruleForm')"
                                  class="btn btn-brown btn-md"
                                  style="width: 100%; background-color: #D96B12; color: white;"
                                >
                                  Get a Free Quote
                                  <span class="el-icon-d-arrow-right"></span>
                                </el-button>
                              </el-form-item>
                            </div>
                          </div>

                        </el-form>

                        <div style="background: #f0f9eb;">
                          <div class="mt-3 w-100">
                            <hr />
                            <ul class="check-list">
                              <li>Best price guarantee</li>
                              <li>Quotes are provided without any obligation to book</li>
                              <li>Your request will be sent directly to us</li>
                            </ul>

                          </div>
                        </div>
                      </div>
                    </article>
                  </section>
                </div>
                <div
                  v-else
                  style="height: 100%;"
                >
                  <div
                    class="skeleton-block skeleton-effect-wave"
                    style="height: 300px; width: 100%; border-radius: 5px;"
                  ></div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </article>
  </main>
</template>

<script>
import { Tabs, TabPane } from "element-ui";
export default {
  components: {
    "el-tabs": Tabs,
    "el-tab-pane": TabPane,
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      loadingError: false,

      safari_tours: [],
      safari_tour: {},
      days: [],
      rates: [],
      activities: [],
      amount_per_person: "",
      amount_range_price: "",
      initial_persons_no: "",
      inputnumber: 1,

      checkInDate: "",
      adults_num: 1,
      children_num: 1,
      inclusions_and_exclusion: "",
      getting_there: "",

      ruleForm: {
        check_in_date: "",
        number_adult_guest: "",
        number_children_guest: "",
      },

      rules: {
        check_in_date: [
          {
            type: "date",
            required: true,
            message: "Please pick Check-In Date",
            trigger: "change",
          },
        ],
        number_adult_guest: [
          {
            required: true,
            message: "Please select number of Adults",
            trigger: "change",
          },
        ],
        number_children_guest: [
          {
            required: true,
            message: "Please select number of Children",
            trigger: "change",
          },
        ],
      },

      safariPackageForm: {
        destination_country: "",
        check_in_date: "",
        number_adult_guest: "",
        number_children_guest: "",
      },
    };
  },

  computed: {
    totalTravellers() {
      return (
        this.ruleForm.number_adult_guest + this.ruleForm.number_children_guest
      );
    },

    totalRateAmount() {
      let totalTravellers =
        this.ruleForm.number_adult_guest + this.ruleForm.number_children_guest;

      return this.rates.find(
        (rate) => rate.number_of_travellers == totalTravellers
      );
    },

    totalRateAmountForMorePeople() {
      return this.rates.find((rate) => rate.number_of_travellers == 6);
    },
  },

  mounted() {
    this.getSafariPackageDetail();
  },

  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    handleChange(value) {
      console.log(value);
    },

    handleClose(done) {
      done();
    },
    async getSafariPackageDetail() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `api/safari/package/get/${this.$route.params.packageId}`
        );
        if (request.data.success) {
          this.safari_tour = request.data.safari_tour;
          this.amount_per_person = this.safari_tour.amount;
          this.getting_there = request.data.safari_tour.getting_there;
          this.inclusions_and_exclusion =
            request.data.safari_tour.inclusions_and_exclusion;

          this.activities = request.data.activities;
          this.days = request.data.safari_tour_days;
          this.rates = request.data.safari_rates;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
      } finally {
        this.isLoading = false;
      }
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "success-row";
      } else if (rowIndex === 3) {
        return "success-row";
      } else if (rowIndex === 5) {
        return "success-row";
      }
      return "";
    },

    tableHeaderRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "success-row";
      }
      return "";
    },

    requestAQuote() {
      if (this.ruleForm.check_in_date != "") {
        this.submitForm;
      } else {
        this.router.push({
          Path: `/package-detail/${this.$route.params.packageTitle}/${this.$route.params.packageId}#request-quote`,
        });
      }
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          localStorage.setItem(
            "tour_details",
            JSON.stringify(this.safari_tour)
          );
          localStorage.setItem(
            "request_start",
            JSON.stringify({
              check_in_date: this.ruleForm.check_in_date,
              number_adult_guest: this.ruleForm.number_adult_guest,
              number_children_guest: this.ruleForm.number_children_guest,
              rate_amount:
                this.totalTravellers > 6
                  ? this.totalRateAmountForMorePeople.rate_amount
                  : this.totalRateAmount.rate_amount,
            })
          );
          this.$router.push({
            name: "RequestQuote",
            params: {
              packageId: `${this.$route.params.packageId}`,
              packageTitle: `${this.$route.params.packageTitle}`,
            },
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
.package_details_container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
}

.package_details_container > div:first-child {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 5px;
}

.highlights_container {
  position: relative;
  height: 60vh;
  border-radius: 5px;
}

.highlights {
  border-radius: 5px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  padding: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.highlights_description {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  padding: 20px 30px;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  );

  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.highlights_description > div {
  width: 50%;
}

.highlights_description > div h1 {
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  font-size: 2em;
  color: white;
}

.highlights img {
  height: 60vh;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.day_image_description {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  display: flex;
  align-items: flex-end;
  padding: 20px;
}

.check-list {
  margin: 0;
  padding-left: 0px;
}

.check-list li {
  position: relative;
  list-style-type: none;
  padding-left: 2.5rem;
  margin-bottom: 0.5rem;
}

.check-list li:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0px;
  width: 5px;
  height: 11px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: #00a8a8;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .package_details_container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  .interested_mobile {
    display: none;
  }
  .highlights_container {
    height: 40vh;
  }
  .highlights img {
    height: 40vh;
  }
  .highlights_description {
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }
  .highlights_description > div {
    width: 50%;
  }

  .highlights_description > div span {
    text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
      0px -4px 10px rgba(255, 255, 255, 0.3);
    font-size: 2em;
    color: white;
  }
  .package_tabs {
    width: 92vw !important;
  }

  .highlights_description > div h1 {
    font-size: 1.5em;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .package_details_container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  .interested_mobile {
    display: none;
  }
  .highlights_container {
    height: 40vh;
  }
  .highlights img {
    height: 40vh;
  }
  .highlights_description {
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }
  .highlights_description > div {
    width: 100%;
  }

  .highlights_description > div span {
    text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
      0px -4px 10px rgba(255, 255, 255, 0.3);
    font-size: 1.5em;
    color: white;
  }
  .package_tabs {
    width: 92vw !important;
  }

  .highlights_description > div h1 {
    font-size: 1.5em;
  }
}
</style>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #d96b12;
  font-weight: 600;
  background-color: #fff;
  border-right-color: #dcdfe6;
  border-left-color: #dcdfe6;
}

.el-icon-arrow-right:before {
  color: #d96b12;
}
.el-icon-arrow-left:before {
  color: #d96b12;
}
.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}

.el-form-item__label {
  padding: 0 0 0 0;
}
label {
  display: inline-block;
  margin-bottom: 0rem;
}

.highlights_description .el-icon-arrow-right:before {
  color: grey;
}
.highlights_description .el-icon-arrow-left:before {
  color: grey;
}

.highlights_description .el-breadcrumb__inner a,
.highlights_description .el-breadcrumb__inner.is-link {
  font-weight: 700;
  text-decoration: none;
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: white;
}

.highlights_description .el-breadcrumb__inner {
  color: #eeeeee;
}

.highlights_description .el-breadcrumb__item:last-child .el-breadcrumb__inner,
.highlights_description .el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.highlights_description
  .el-breadcrumb__item:last-child
  .el-breadcrumb__inner
  a:hover,
.highlights_description
  .el-breadcrumb__item:last-child
  .el-breadcrumb__inner:hover {
  color: #dedcdc;
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .el-tab-pane .onDesktopOnly {
    display: none;
  }

  .highlights_description .el-breadcrumb__inner.is-link {
    font-weight: 700;
    font-size: 0.5em;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .el-tab-pane .onDesktopOnly {
    display: none;
  }

  .highlights_description .el-breadcrumb__inner.is-link {
    font-weight: 700;
    font-size: 0.6em;
  }

  .highlights_description .el-icon-arrow-right:before {
    color: grey;
    font-size: 0.6em;
  }
  .highlights_description .el-icon-arrow-left:before {
    color: grey;
    font-size: 0.6em;
  }
  .highlights_description .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .highlights_description
    .el-breadcrumb__item:last-child
    .el-breadcrumb__inner
    a,
  .highlights_description
    .el-breadcrumb__item:last-child
    .el-breadcrumb__inner
    a:hover,
  .highlights_description
    .el-breadcrumb__item:last-child
    .el-breadcrumb__inner:hover {
    font-size: 0.6em;
  }
}
</style>