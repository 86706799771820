<template>
  <div v-loading="isSubmitting">
    <section>
      <article>
        <div class="container mt-5 mb-5">
          <div class="row">
            <div class="col-md-8 quote_steps">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/safari-packages' }">Select your Package</el-breadcrumb-item>
                <el-breadcrumb-item><strong>Enter your details</strong></el-breadcrumb-item>
                <el-breadcrumb-item>Operator Emails You a Quote</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">

              <section>
                <div
                  class="quote_package_grid mt-4 mb-5"
                  data-aos="zoom-in-down"
                  data-aos-duration="3000"
                >
                  <div class="quote_details">
                    <div
                      style="cursor: pointer;"
                      @click="$router.push({ path: `/package-detail/${safari_tour.title}/${safari_tour.id}` })"
                    >
                      <img :src="safari_tour.image">
                      <div>
                        <a>
                          <div class="text-white">
                            <span style="font-size: 1.25em;"><strong>{{safari_tour.title}}</strong></span>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div>
                      <div style="margin: 20px 0px;">
                        <span style="font-size: 1.2em;"><strong>${{ travelers_brief.rate_amount}} pp (USD).</strong></span><br>
                        <span>
                          <small>
                            {{safari_tour.brief_description | truncate(180, '...')   }}
                            <!-- {{ (tour.brief_description.length>200)? (tour.brief_description | slice:0:200)+'...':(tour.brief_description) }} -->
                          </small>
                        </span>
                      </div>
                      <div style="border-top: 1px solid #eeeeee; margin: 20px 0px;">
                        <span><small>Access Uganda Safaris.</small></span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <article style="
                padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;">
                  <div>
                    <h2 style="font-size: 1.8em; font-weight: 600;">Get a Free Quote for This Tour</h2>
                    <p>Requesting a quote is free and there is no obligation to book</p>

                    <el-form
                      :model="quoteForm"
                      :rules="rules"
                      label-position="top"
                      ref="quoteForm"
                      label-width="200px"
                      class="demo-quoteForm"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <el-form-item
                            label="Full name"
                            prop="name"
                          >
                            <el-input
                              v-model="quoteForm.name"
                              placeholder="Full name"
                            ></el-input>
                          </el-form-item>
                        </div>
                        <div class="col-md-6">
                          <el-form-item
                            label="Email"
                            prop="email"
                          >
                            <el-input
                              v-model="quoteForm.email"
                              placeholder="Email"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <el-form-item
                            label="Country of Residence:"
                            prop="country_of_residence"
                          >
                            <el-input
                              v-model="quoteForm.country_of_residence"
                              placeholder="Country of Residence"
                            ></el-input>
                          </el-form-item>
                        </div>
                        <div class="col-md-6">
                          <el-form-item
                            label="Mobile Phone Number"
                            prop="phone_number"
                          >
                            <el-input
                              v-model="quoteForm.phone_number"
                              placeholder="Mobile Phone Number"
                            ></el-input>
                            <span style="color: grey;">
                              We need a phone number in case the operator cannot reach you by email.
                            </span>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <el-form-item
                            label="Comments"
                            prop="comments"
                          >

                            <span style="color: grey; line-height: 10%;">
                              You will receive the best response from the tour operator if you introduce
                              yourself and share the reasons for your interest in this tour.
                            </span>
                            <el-input
                              type="textarea"
                              v-model="quoteForm.comments"
                              placeholder="Type message here ..."
                            ></el-input>
                          </el-form-item>
                        </div>
                      </div>
                      <el-form-item>
                        <div>
                          <p>
                            <strong>Please check if the email you've entered is correct:</strong><br />
                            {{quoteForm.email}}
                          </p>
                        </div>
                        <el-button
                          class="mt-5"
                          style="background-color: #D96B12; color: white;"
                          @click="submitForm('quoteForm')"
                        >Send Request <i class="el-icon-position"></i></el-button>
                        <!-- <el-button @click="resetForm('quoteForm')">Reset</el-button> -->

                        <p style="color: grey;">
                          By clicking the 'Send' button you agree to our Terms of Use and Privacy Policy
                        </p>
                      </el-form-item>
                    </el-form>
                  </div>
                </article>
              </section>
            </div>
            <div class="col-md-4">
              <div
                class=" mt-4 mb-5"
                style="
                padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;"
              >
                <h2 style="font-size: 1.3em; font-weight: 600;">Your Trip Details</h2>
                <hr />
                <div>
                  <small style="color: grey;">Travelers</small><br />
                  <span>
                    {{travelers_brief.number_adult_guest}}
                    {{travelers_brief.number_adult_guest == 1 ? ` Adult` : `Adults`}}
                    <span v-if="travelers_brief.number_children_guest != 0">
                      , {{travelers_brief.number_children_guest}}
                      {{travelers_brief.number_children_guest == 1 ? ` Child` : `Children`}}
                    </span>
                  </span><br />
                </div>
                <div>
                  <hr />
                  <small style="color: grey;">Start Date</small><br />
                  <span>
                    {{ formattedDate(travelers_brief.check_in_date) }}
                  </span>
                </div>
                <div>
                  <hr />
                  <small style="color: grey;">Tour Rate</small><br />
                  <span>
                    ${{ travelers_brief.rate_amount}} per person (USD)
                  </span><br />
                  <small>*A discount for children may apply. Complete this request to learn more.</small>
                </div>
                <!-- {{ travelers_brief }} -->
              </div>
            </div>
          </div>

          <div class="quote_grid">
            <div></div>
            <div></div>
          </div>

        </div>
      </article>
    </section>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
export default {
  data() {
    return {
      isSubmitting: false,
      active: 1,
      safari_tour: {},
      travelers_brief: {},

      quoteForm: {
        name: "",
        country_of_residence: "",
        email: "",
        phone_number: "",
        comments: "",
      },

      rules: {
        name: [
          {
            required: true,
            message: "Full Name is required",
            trigger: "blur",
          },
        ],
        country_of_residence: [
          {
            required: true,
            message: "Country of Residence is required",
            trigger: "blur",
          },
        ],
        phone_number: [
          {
            required: true,
            message: "Phone Number is required",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Email is required",
            trigger: "blur",
          },
        ],
        comments: [
          {
            required: true,
            message: "Comment is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy");
    },
  },

  mounted() {
    this.safari_tour = JSON.parse(localStorage.getItem("tour_details"));
    this.travelers_brief = JSON.parse(localStorage.getItem("request_start"));
  },

  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.isSubmitting = true;
            let request = await this.$http.post(
              `api/safari/package/get_qoute`,
              {
                full_name: this.quoteForm.name,
                tour_id: this.safari_tour.id,
                email: this.quoteForm.email,
                country: this.quoteForm.country_of_residence,
                tour_rate: this.safari_tour.amount,
                start_date: this.travelers_brief.check_in_date,
                travellers: {
                  number_of_adults: this.travelers_brief.number_adult_guest,
                  number_of_children:
                    this.travelers_brief.number_children_guest,
                },
                phone: this.quoteForm.phone_number,
                message: this.quoteForm.comments,
              }
            );
            if (
              request.data.success &&
              request.data.message == "QUOTE_SENT_SUCCESSFULLY"
            ) {
              this.$notify({
                title: "Success",
                message: "Package Quotation Submitted Successfully",
                type: "success",
              });
              this.$router.push({
                path: "/thank-you",
                query: { email: `${this.quoteForm.email}` },
              });
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            if (error.message == "NetworkError") {
              this.isSubmitting = false;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred please try again",
                type: "warning",
              });
            }
            this.isSubmitting = false;
            this.$notify({
              title: "Submission Failed",
              message: "Unable to Submit Quote, Please try again",
              type: "error",
            });
          } finally {
            this.isSubmitting = false;
          }
        } else {
          this.$notify({
            title: "Submission Error",
            message: "Unable to Submit Quote Data.",
            type: "error",
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
.quote_grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
}

.quote_grid > div {
  background-color: #eeeeee;
}

.highlights {
  position: absolute;
  top: 0px;
  bottom: 0px;
  padding: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.highlights_description {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  padding: 20px 30px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.quote_package_grid {
  /* -webkit-box-shadow: 5px 5px 8px -5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 5px 5px 8px -5px rgba(0, 0, 0, 0.15); */
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.quote_details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  transition: 1s;
}
.quote_details img {
  border-radius: 0px 0px 0px 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 2s ease;
}

.quote_details > div:first-child {
  position: relative;
}
.quote_details > div:first-child > div {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  display: flex;
  align-items: flex-end;
}

.quote_details > div:first-child > div a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 10px 20px;
  text-decoration: none;
}

.quote_details > div:nth-child(2) {
  transition: all 2s ease;
  border-radius: 0px 0px 0px 0px;
  padding: 20px 20px 20px 20px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .quote_details {
    display: grid;
    grid-template-columns: 1fr;
    transition: 1s;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .quote_details {
    display: grid;
    grid-template-columns: 1fr;
    transition: 1s;
  }

  .quote_details > div:first-child {
    height: 200px;
  }

  .quote_details img {
    height: 200px;
  }
}
</style>
<style>
.el-form-item {
  margin-bottom: 0px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
label {
  display: inline-block;
  margin-bottom: 0px;
}

.quote_steps .el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  color: #a7dc8d;
}
</style>